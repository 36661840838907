<template>
  <DLXManager />
</template>

<script>
import DLXManager from './components/DLXManager.vue';

export default {
  name: 'App',
  components: {
    DLXManager,
  },
};
</script>
