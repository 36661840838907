<template>
    <div v-if="visible" :class="['fixed bottom-4 right-4 p-4 rounded shadow-md', notificationClass]" @click="hide">
        {{ message }}
    </div>
</template>

<script>
export default {
    name: 'ApiNotification',
    data() {
        return {
            visible: false,
            message: '',
            type: 'success',
        };
    },
    computed: {
        notificationClass() {
            return {
                'bg-green-500 text-white': this.type === 'success',
                'bg-red-500 text-white': this.type === 'error',
            };
        },
    },
    methods: {
        show(message, type = 'success') {
            this.message = message;
            this.type = type;
            this.visible = true;
            setTimeout(this.hide, 3000); // Ocultar automáticamente después de 3 segundos
        },
        hide() {
            this.visible = false;
        },
    },
};
</script>

<style scoped>
/* Agregar estilos personalizados si es necesario */
</style>